import * as React from 'react';

import ACRO from './icons/acro.png';
import BASE from './icons/baseball.png';
import BASK from './icons/basketball.png';
import FENC from './icons/fencing.png';
import FHOC from './icons/field-hockey.png';
import FOOT from './icons/football.png';
import HOCK from './icons/hockey.png';
import LAX from './icons/lacrosse.png';
import ROW from './icons/rowing.png';
import RUG from './icons/rugby.png';
import SOCC from './icons/soccer.png';
import SOFT from './icons/softball.png';
import SWIM from './icons/swim.png';
import TENN from './icons/tennis.png';
import TF from './icons/track-field.png';
import VOLL from './icons/volleyball.png';
import WPOLO from './icons/wpolo.png';
import WRSTL from './icons/wrstl.png';
import { Mapping, Props } from './types';

const mapping: Mapping = {
  MCBASE: BASE,
  MCBASK: BASK,
  MCFENC: FENC,
  MCFOOT: FOOT,
  MCHOCK: HOCK,
  MCLAX: LAX,
  MCROW: ROW,
  MCRUG: RUG,
  MCSOCC: SOCC,
  MCSWM: SWIM,
  MCTENN: TENN,
  MCTF: TF,
  MCVOLL: VOLL,
  MCWPOLO: WPOLO,
  MCWRSTL: WRSTL,
  WCACRO: ACRO,
  WCBASK: BASK,
  WCFENC: FENC,
  WCFHOC: FHOC,
  WCHOCK: HOCK,
  WCLAX: LAX,
  WCROW: ROW,
  WCRUG: RUG,
  WCSOCC: SOCC,
  WCSOFT: SOFT,
  WCSWM: SWIM,
  WCTENN: TENN,
  WCTF: TF,
  WCVOLL: VOLL,
  WCWPOLO: WPOLO,
  WCWRSTL: WRSTL,
};

const SportIcon: React.FunctionComponent<Props> = function SportIcon({
  sport, alt = '', ...props
}: Props): JSX.Element {
  return <img src={mapping[sport]} alt={alt} {...props} />;
};

export default SportIcon;
