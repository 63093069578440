import { ReactComponent as FastForward } from '@mdi/svg/svg/fast-forward.svg';
import { ReactComponent as Fullscreen } from '@mdi/svg/svg/fullscreen.svg';
import { ReactComponent as History } from '@mdi/svg/svg/history.svg';
import { ReactComponent as Keyboard } from '@mdi/svg/svg/keyboard.svg';
import { ReactComponent as Rewind } from '@mdi/svg/svg/rewind.svg';
import { ReactComponent as Update } from '@mdi/svg/svg/update.svg';
import * as React from 'react';
import { GameStatus } from 'weplayed-typescript-api';

import { formatDuration } from 'common/utils/time';

import { GameStatusLabel } from '../GameStatusLabel';
import {
  SHORTCUT, SHORTCUT_KEYS, VideoPlayerContext,
} from '../VideoPlayer/constants';
import { VolumeControlButton } from '../VolumeControlButton';
import * as s from './GamePlayerControls.m.less';

const fakedGame = {
  live_now: true,
  status: GameStatus.CAPTURE_STARTED,
  review_qa_status: null,
};

export const GamePlayerControls: React.FC = React.memo(() => {
  const updateTimer = React.useRef<ReturnType<typeof setInterval>>();

  const {
    controls,
    duration,
    edge,
    handleFaster,
    handleForward,
    handleFullscreen,
    handleHelp,
    handleMute,
    handleRewind,
    handleSeek,
    handleSlower,
    handleVolume,
    live,
    muted,
    playing,
    position,
    volume,
  } = React.useContext(VideoPlayerContext);

  const [time, setTime] = React.useState(null);

  const handleEdge = React.useCallback(() => {
    handleSeek(Number.POSITIVE_INFINITY);
  }, [handleSeek]);

  React.useEffect(() => {
    if (playing && controls) {
      updateTimer.current = setInterval(() => {
        const p = position();
        const d = duration();

        setTime(`${formatDuration(p)}${d && Number.isFinite(d) ? ` / ${formatDuration(d)}` : ''}`);
      }, 1000);

      return (): void => clearInterval(updateTimer.current);
    }
  }, [duration, playing, position, live, controls]);

  return (
    <>
      {live && (
        <GameStatusLabel
          className={s.live}
          edge={edge}
          onClick={!edge && handleEdge}
          game={fakedGame}
        />
      )}
      <div className={s.root}>
        <div className={s.time}>
          {time}
        </div>
        <button
          className="fullstory-ignore"
          onClick={handleHelp}
          title={SHORTCUT_KEYS[SHORTCUT.FULLSCREEN][2]}
          type="button"
        >
          <Keyboard />
        </button>
        <button
          className="fullstory-ignore"
          onClick={handleRewind}
          title={SHORTCUT_KEYS[SHORTCUT.SKIP_BACK][2]}
          type="button"
        >
          <History />
        </button>
        <button
          title={SHORTCUT_KEYS[SHORTCUT.SKIP_FWD][2]}
          className="fullstory-ignore"
          onClick={handleForward}
          type="button"
        >
          <Update />
        </button>
        <button
          className="fullstory-ignore"
          disabled={!handleSlower}
          onClick={handleSlower}
          title={SHORTCUT_KEYS[SHORTCUT.SLOW_DOWN][2]}
          type="button"
        >
          <Rewind aria-label={SHORTCUT_KEYS[SHORTCUT.SLOW_DOWN][2]} />
        </button>
        <button
          className="fullstory-ignore"
          disabled={!handleFaster}
          onClick={handleFaster}
          title={SHORTCUT_KEYS[SHORTCUT.SPEED_UP][2]}
          type="button"
        >
          <FastForward />
        </button>
        <button
          title={SHORTCUT_KEYS[SHORTCUT.FULLSCREEN][2]}
          onClick={handleFullscreen}
          type="button"
        >
          <Fullscreen aria-label={SHORTCUT_KEYS[SHORTCUT.FULLSCREEN][2]} />
        </button>
        <VolumeControlButton
          muted={muted}
          volume={volume}
          onMute={handleMute}
          onVolume={handleVolume}
        />
      </div>
    </>
  );
});

GamePlayerControls.displayName = 'GamePlayerControls';
