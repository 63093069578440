import * as React from 'react';

import { VideoPlayer } from '../VideoPlayer';
import { VideoPlayerRefType } from '../VideoPlayer/types';
import * as s from './GamePlayer.m.less';
import { GamePlayerControls } from './GamePlayerControls';
import { GamePlayerProps, HelpProps } from './types';

const Help: React.FC<HelpProps> = function Help({
  segments,
  onMomentCreate,
}) {
  if (!segments && !onMomentCreate) {
    return null;
  }

  return (
    <>
      Moments
      <dl>
        <dt title="Enter">⏎</dt>
        <dd>Create / Edit Moment</dd>
        {segments && (
          <>
            <dt>1...{segments.length}</dt>
            <dd>Jump to period/quarter etc</dd>
          </>
        )}
      </dl>
    </>
  );
};

export const GamePlayer = React.memo(React.forwardRef<
  VideoPlayerRefType, GamePlayerProps
>(({ onMomentCreate, position: $position, controls, segments, ...props }, ref) => {
  const [position, setPosition] = React.useState($position);

  React.useEffect(() => {
    setPosition($position);
  }, [$position]);

  const keyListener = React.useCallback((key: string): boolean => {
    if (key === 'Enter' && onMomentCreate) {
      onMomentCreate();
      return true;
    }

    if (segments && ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)) {
      const segment = parseInt(key, 10) - 1;
      if (typeof segments[segment] !== 'undefined') {
        setPosition(segments[segment]);
        return true;
      }
    }

    return false;
  }, [onMomentCreate, segments]);

  return (
    <div className={s.root}>
      <VideoPlayer
        {...props}
        controls={controls ? <GamePlayerControls /> : null}
        help={<Help segments={segments} onMomentCreate={onMomentCreate} />}
        onKeyDown={keyListener}
        position={position}
        ref={ref}
      />
    </div>
  );
}));

GamePlayer.displayName = 'GamePlayer';
